/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WWCharacter } from '../common/components/ww-character';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../common/components/youtube-embed';

interface IProps {
  name: string;
  element: string;
  role: string;
}

export const WWTeams: React.FC<IProps> = ({ name, element, role }) => {
  const verinaInfo =
    "as a healing support can easily accommodate running the 5P Rejuvenating Glow set alongside a Bell-Borne Geochelone echo, offering the team a wide array of offensive and defensive buffs already bringing a tonne of worth just from that alone. Additionally Verina's many rotational options to access her Ultimate and Concerto are all fast and relatively easy to execute, leaving ample field time for the rest of team mates to execute their combos. Verina's Outro is by far the most flexible and powerful Outro in the game granting all allies 15% generic DMG% Amplify for 30 seconds. Most units only grant 1 type and only for the very next unit to switch in locking them into staying on field less they lose the buff but Verina grants it to all allies completely unrestricted. On top of this Verina grants an additional 20% ATK and a Cheat Death ability preventing one death every 10 minutes. She's universal, flexible, takes little field time and outstanding in every team.";
  const shoreInfo =
    'is incredibly flexible thanks to her generic buffs, lingering Outro amplification and relatively low field time requirements after her first rotation. Because of this, she can be played as a support in most team compositions to great effect.';
  return (
    <>
      <p>
        Below you can find a list of some of the stronger synergies between this
        character and others.
      </p>
      {(name === 'verina' ||
        name === 'baizhi' ||
        name === 'rover-spectro' ||
        name === 'the-shorekeeper') && (
        <>
          <div className="info-box">
            <p>
              <strong className={`char-name ${element} `}>{name}</strong>{' '}
              <strong>is very flexible</strong> and can be be paired with most
              characters and fulfill the Support role in the team.{' '}
              {name === 'rover-spectro' &&
                'Keep in mind you will need S4 Rover Spectro to fulfil the role as that is when they obtain their heal.'}
            </p>
          </div>
        </>
      )}
      {name === 'aalto' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jiyan" enablePopover /> - Aalto
              isn't Jiyan's best support but he certainly gets the job done
              coming close enough to the total team damage output of Mortefi
              teams when all units are at S0 albeit with a few downsides.
              Aalto's main claim to fame is his 23% Aero DMG Amplify buff that
              like Mortefi's is a special multiplier boosting up Jiyan's entire
              burst window. This alone matches the Heavy Amplify bonus from
              Mortefi as not all of Jiyan's attacks are Heavy but all of them
              are Aero. While Aalto's Aero buff is excellent, he doesn't have
              many other damage focused synergies with Jiyan and has a slightly
              longer rotation, requires some positional maneuvering around his
              Gates and is harder to play overall. If you like Aalto and or
              don't like or get Mortefi he is a good substitute.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> -
              Aalto's best option for his Main DPS playstyle. Sanhua has the
              shortest rotation of any hybrid, as well as an incredible 38%
              Basic Attack DMG Amplify, making her the best option to buff
              Aalto's damage, mostly being of the Basic Attack type.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'calcharo' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> -
              Supporting Calcharo's field time requirements by doing lots of
              off-field damage, Yinlin's outro - accessed rather quickly -
              cements her as the best partner for Calcharo. Buffing the majority
              of his damage types, being Electro and Liberation damage, on top
              of being able to use Calcharo's many swap cancel windows if you
              wish to play him in that style, Yinlin complements all elements of
              his kit close to perfectly.
            </li>
            <ul>
              <li>
                It should be highlighted that while Calcharo can be played swap
                cancel style for consistency purposes, swap cancels make the
                team lose out overall on DPS by forfeiting Yinlin's buffs during
                Calcharo's rotation - it just makes the whole rotation likely to
                be more inconsistent in the case that Death Messengers (which
                make up the majority of Calcharo's damage) get interrupted by
                enemy attacks, which happens fairly often.{' '}
              </li>
            </ul>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'camellya' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - A
              large amount of Camellya's damage is counted as "Basic Attack"
              damage which Sanhua can buff with her Outro skill that amplifies
              Basic ATK DMG by 38% offering a substantial boost to her output.
              Sanhua's Outro is excellent but her field time is also some of the
              shortest of the game, allowing Camellya and the support of your
              choosing more time for themselves. At higher Sequences, Sanhua's
              supportive capabilities only improve further specially at S6,
              offering a 20% ATK team wide buff, further boosting Camellya's
              capabilities. Due to Camellya's long rotation you'll want to time
              Sanhua's buffs right before you swap to her and execute her damage
              combo.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'changli' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> -
              Changli thrives with a partner who can take advantage of the Quick
              Swap opportunities she presents - Yinlin as a Quick Swap
              specialist herself has incredible synergy with her for that
              reason. Cycling between Yinlin's Forte, Resonance Skill,
              Liberation, Echo and all of Changli's cooldown-based combos makes
              for an incredibly synergistic partnership - all topped off by the
              fact Yinlin can deliver a good amount of her damage off field,
              allowing Changli to take up the extra field time her Kit demands.
              On top of this, both Changli and Yinlin each buff each other
              Liberation damage with their Outros, enhancing each of their
              damage contributions by a decent amount. Yinlin and Changli aren't
              a perfect match but they work well together for those happy with a
              rapid Quick Swap playstyle.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> has the
              option to gear her playstyle towards being a top tier Quick Swap
              damage dealer, making her a fantastic partner for Changli. Encore
              can weave her Resonance Skill, Echo and Forte/Ultimate burst in
              between Changli's cooldowns to fill the brief moments granted by
              her Quick Swap nature, ensuring the team's DPS stays high. On top
              of this Encore can make use of the Fusion Damage amplification
              portion of Changli's Outro skill boosting the damage of some of
              her abilities throughout combat.
            </li>
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> is
              Changli's highest damage potential partner. The dynamic duo's
              reliance on complex swap cancels to unlock its true potential
              makes it less accessible to newer players. However, the two
              characters can effectively be played at once via their many swap
              cancellable high-damaging attacks, making the duo one of the top
              competitive teams in the game. Beware of its complexity though.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> is
              one of the top Quick Swap damage dealers in the game, making them
              a great potential team mate for Changli who also falls within the
              same archetype. During the Quick Swap opportunities granted by
              Changli, Rover can make use of either or both their Resonance
              Skills, their Ultimate and their incredibly powerful dreamless
              echo. All of these abilities are great complimentary cooldowns to
              Changli's kit and combat rotation dealing high damage and being
              fast enough to allow for an immediate swap back to Changli as
              needed. Rover's Ultimate also hits like a freight train -
              something Changli can amplify further thanks to her Outro if
              aligned correctly further boosting synergy.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'chixia' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - While
              Chixia isn't Changli's highest damaging partner as she has trouble
              making use of Changli's many swap cancel windows, Chixia very much
              appreciates Changli's presence in a double DPS team. Remaining
              competitive comparatively to most Changli teams while remaining
              easy to play by double DPS team standards, as the few swap cancels
              you can do don't improve the team's damage too significantly, and
              with Chixia being able to fully utilize Changli's outro that
              increases Fusion and Liberation damage, Changli makes a very
              suitable partner for Chixia.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'danjin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> is by
              far Danjin's best main DPS to support. While Sanhua remains
              slightly more effective than Danjin due to her extremely low field
              time, Danjin remains an extremely competitive option thanks to her
              Havoc DMG amplify provided by her Outro skill and her own
              short-length rotation. Danjin can also provide additional buffs to
              Camellya with her S6 and Moonlit Clouds, making the team one of
              the best in the game.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> is
              another really strong DPS option for Danjin to support along with
              Camellya, this time on the same level as Sanhua's buffing
              potential. Still retaining Danjin's relatively low field time, her
              Outro Havoc DMG Amplify, and the other buffs provided by Moonlit
              Clouds and Danjin's S6, Danjin and Havoc Rover make for a very
              strong team together that can easily clear the whole tower with
              good investment.
            </li>
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> is
              Danjin's best Main DPS style option. Being able to quickly provide
              buffs via the Moonlit Clouds set and potentially his S6, as well
              as retaining good off-field damage and a 38% Heavy Attack DMG
              Amplify - which is the type that makes up most of Danjin's damage
              - the Mortefi-Danjin duo ends up being surprisingly strong.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'encore' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> is
              without a doubt Encore's best partner. Sanhua's short rotation
              times and many swap cancellable windows, as well as her huge Outro
              skill buff of 38% Basic Attack DMG Amplify, to go along with
              Moonlit Clouds and potentially her S6 that buff damage even more,
              coupled with Encore's tendency to swap cancel her Forte, Skill and
              Echo, make for a team that seriously packs a punch while remaining
              fairly easy to play.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> abuses
              Encore's swap cancel windows and takes it to the next level. While
              Encore's duo with Changli doesn't output as much damage as Sanhua
              and is harder to play, freeing up Sanhua makes this duo worthy of
              consideration. They end up working surprisingly well together
              thanks to Changli's outro which can amplify Encore's Fusion DMG by
              25%, and Encore's ultimate rotation not being too lengthy, making
              this a solid option.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'jianxin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="calcharo" enablePopover /> - A
              large portion of Calcharo's burst damage is Resonance Liberation
              damage which <strong>Jianxin</strong> can buff up with her 38%
              Resonance Liberation DMG % "Amplify" buff, giving Calcharo a
              substantial boost to his main damage window. When playing Jianxin
              with Calcharo you'll need to focus on maximizing her Concerto but
              keeping her field time low to ensure Calcharo has all the time he
              needs for his combos. Jianxin's Liberation is a great tool on top
              of her already fantastic Outro boost for Calcharo, allowing her to
              group up trash enemies for Calcharo to cleave down.
            </li>
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> excels
              at buffing heavy ATK DMG with his "Amplify" Outro which acts as an
              immense multiplier thanks to the special "Amplify" scaling
              mechanic of which Jianxian's Primordial Chi Sprial is entirely
              Heavy. When playing Jianxin as a damage dealer, she demands high
              field time and Mortefi takes up very little making the pair a
              decent combo of burst and buffs with enough room to fit in a
              support. Mortefi's ultimate unleashes a slew of Coordinated
              Attacks over its duration but requires a high hit counter to
              maximize its effects. During her Forte Gauge, Jianxin attacks over
              3 times per second easily maxing out this requirement. Mortefi's
              ultimate also fires 2 Coordinated Attack instead of 1 when the
              triggering hit is a heavy attack all of which of course the entire
              channel of Jianxin's Forte is.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yuanwu" enablePopover /> is an
              excellent Hybrid partner for Jinhsi who - out of all of her
              potential hybrid teammates - requires the least field time by far.
              Yuanwu doesn't offer to much in terms of total damage or buffs but
              he does offer a consistent permanently accessible Electro
              Coordinated Attack to assist with stacking up Jinhsi's Forte
              Gauge. Yuanwu is absolutely a top option for Jinhsi and should not
              be underestimated - he is easy to play, gives her what she needs
              and allows for you to focus on playing 2 characters for the most
              part instead of 3 due to his simplistic nature.
            </li>
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> - Jinhsi
              thrives in teams with teammates that can launch consistent
              follow-up attacks from off-field, preferably without taking up to
              much field time and Zhehzi fits that bill perfectly. If that
              wasn't enough though Zhezhi also offers Resonance Skill DMG
              Amplification on her outro and Jinhsi is currently the biggest
              Resonance Skill damage dealer in the game with roughly 75% of her
              damage being of the type. Finally Zhezhi also provides Jinhsi a
              bit of extra resonance energy allowing for more ultimates which is
              always welcome.
            </li>
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> is
              currently one of the best teammates for Jinhsi... if you can
              manage playing them both effectively at once! Out of all Jinhsi's
              current Hybrid teammates, Yinlin is the hardest to play optimally
              due to her having the strictest requirements to access her
              Coordinated Attacks. If you're a master of quick swapping, swap
              cancelling and are ok with a bit more of a complicated team to
              play, Yinlin will certainly reward you though. Like Yuanwu she can
              permanently keep active a source of Electro Coordinated Attacks
              for Jinhsi's Forte Gauge generation, but on top of this Yinlin
              also does outstanding damage of her own allowing her to pull ahead
              when played just right.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> is
              Jinhsi's best dedicated support. Verina's ultimate applies a mark
              to enemies struck which launches continual Coordinated Attacks
              across its duration - these coordinated attacks assist Jinhsi with
              generating her Forte Gauge Incandecent stacks boosting her damage
              tremendously. Another massive benefit to Jinhsi + Verina is how
              fast and easy Verina's concerto rotation is - this allows Jinhsi
              to gain access to her intro and as a result her burst rotation
              much faster and more effectively smoothing out gameplay immensely.
              Jinhsi can be hard to control in the air during her burst combo
              which can lead to her taking some hits here and there and Verina
              is more than capable of keeping Jinhsi healthy at all times.
              Finally Verina has her massive suite of buffs she offers Jinhsi
              all of which increase her damage massively.
            </li>
          </ul>
        </>
      )}
      {name === 'jiyan' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover /> excels
              at buffing Heavy ATK DMG with his "Amplify" Outro which acts as an
              immense multiplier thanks to the special "Amplify" scaling
              mechanic. Jiyan demands high field time and Mortefi takes up very
              little, making the pair an ideal combo of burst and buffs with
              plenty of wiggle room to fit in a support or some extra action on
              Jiyan while waiting for cooldowns or energy to recharge. Mortefi's
              Ultimate unleashes a slew of Coordinated Attacks over its duration
              but requires a high hit counter to maximize the effect. During his
              ultimate, Jiyan attacks 8 times per dragon strike easily maxing
              out this requirement. Mortefi's ultimate also fires 2 Coordinated
              Attacks instead of 1 when the triggering hit is a Heavy Attack -
              all of which Jiyan's empowered dragon strikes are in his ultimate.
            </li>
            <li>
              <WWCharacter mode="inline" slug="aalto" enablePopover /> isn't
              Jiyan's best support but he certainly gets the job done coming
              close enough to the total team damage output of Mortefi teams when
              all units are at S0 albeit with a few downsides. Aalto's main
              claim to fame is his 23% Aero DMG Amplify buff that like Mortefi's
              is a special multiplier boosting up Jiyan's entire burst window.
              This alone matches the Heavy Amplify bonus from Mortefi as not all
              of Jiyan's attacks are Heavy but all of them are Aero. While
              Aalto's Aero buff is excellent, he doesn't have many other damage
              focused synergies with Jiyan and has a slightly longer rotation,
              requires some positional maneuvering around his Gates and is
              harder to play overall. If you like Aalto and or don't like or get
              Mortefi he is a good substitute.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'lingyang' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> is our
              only Glacio Main damage dealer currently, making him an obvious
              choice to take advantage of Zhezhi's outro Glacio DMG
              Amplification. Lingyang's longer rotations without quick swaps
              also have no issues working well with Zhezhi's infrequent but
              quick rotations she executes every 25 seconds. Both Zhezhi and
              Lingyang synergise decently but more importantly each allow
              eachother to take up exactly how much field time they want.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - A
              large bulk of Lingyang's damage during his ultimate are Basic
              Attacks which <strong>Sanhua</strong> can buff with her Outro
              skill - that increases Basic ATK DMG by 38% "Amplify", increasing
              Lingyang damage substantially. Sanhua's Outro is excellent but her
              field time is also some of the shortest of the game allowing
              Lingyang and the support of your choosing more time for themselves
              or simply a faster rotation. At higher Sequences Sanhua's
              supportive capabilities only improve further specially at Sequence
              6 offering a 20% ATK team wide buff.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'lumi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - Lumi's
              main place in the meta is as a Resonance Skill DMG Amplifying
              Hybrid. As such, Jinhsi appears to be the most natural partner for
              her. And sure enough, 38% Resonance Skill DMG Amplify, which
              comprises 75% of Jinhsi's damage spread, on top of the buffs
              provided by Moonlit Clouds, make her an amazing nuke buffing
              option for Jinhsi.
            </li>
            <ul>
              <li>
                Beware however, that Lumi will generate Jinhsi's Forte stacks
                slowly as she lacks Coordinated Attacks, something Jinhsi really
                wants on her team. Hence, keep in mind with a Jinhsi-Lumi core,
                you are forced to run Verina or Baizhi for their Coordinated
                Attacks, otherwise Jinhsi won't build up close to any stacks on
                her Forte, which would render her skill damage very low.{' '}
              </li>
            </ul>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'mortefi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jiyan" enablePopover /> - Mortefi
              excels at buffing Heavy ATK DMG with his "Amplify" Outro which
              acts as an immense multiplier thanks to the special "Amplify"
              scaling mechanic. Jiyan demands high field time and Mortefi takes
              up very little, making the pair an ideal combo of burst and buffs
              with plenty of wiggle room to fit in a support or some extra
              action on Jiyan while waiting for cooldowns or energy to recharge.
            </li>
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - The
              same reasoning as for Jiyan, applies to Danjin.
            </li>
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> -
              Mortefi is another outstanding Coordinated Attack user who is
              capable of supporting Jinhsi but relies heavily on Sequences to
              get the job done to the standard of some of his competitors in her
              teams. Jinhsi of course gains a tremendous amount of damage from
              having allies constantly launching coordinated attacks - something
              Mortefi can do but at S0 only for 10 seconds at a time per
              ultimate. Unfortunately Mortefi's ultimate has a cooldown of 20
              seconds meaning you'll only have access to coordinated attacks
              half the time. Mortefi's Sequences completely solve this issue -
              at S4 he gains 7 additional seconds of duration for his
              Coordinated Attacks bringing him up to 17 seconds of duration or
              close to 100% uptime and at S6 he gains a 20% ATK boost for the
              whole team. Mortefi can't bring his full kit to bear alongside
              Jinhsi (his 38% Heavy amplification isn't used) but is still
              useful for Jinhsi at S0 however he truly shines at S4 or higher.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="danjin" enablePopover /> - Havoc
              Rover benefits from Danjin's Amplify Outro and together they form
              one of the strongest teams in the game currently - equip both with
              Dreamless Echo and simply destroy everything.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> - A
              large bulk of Rover (Havoc) damage are Basic Attacks which Sanhua
              can buff with her Outro skill - that increases Basic ATK DMG by
              38% "Amplify", increasing Rover (Havoc) damage substantially.
              Sanhua's Outro is excellent but her field time is also some of the
              shortest of the game allowing Rover (Havoc) and the support of
              your choosing more time for themselves or simply a faster
              rotation. At higher Sequences Sanhua's supportive capabilities
              only improve further specially at Sequence 6 offering a 20% ATK
              team wide buff.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'sanhua' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="camellya" enablePopover /> - A
              large amount of Camellya's damage is counted as "Basic Attack"
              damage which Sanhua can buff with her Outro skill that amplifies
              Basic ATK DMG by 38% offering a substantial boost to her output.
              Sanhua's Outro is excellent but her field time is also some of the
              shortest of the game, allowing Camellya and the support of your
              choosing more time for themselves. At higher Sequences, Sanhua's
              supportive capabilities only improve further specially at S6,
              offering a 20% ATK team wide buff, further boosting Camellya's
              capabilities. Due to Camellya's long rotation you'll want to time
              Sanhua's buffs right before you swap to her and execute her damage
              combo.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> - Sanhua
              is once again, as per most of the characters on this list,
              Encore's best in slot partner. Her field time being one of the
              shortest in the game while keeping huge buffs via Moonlit Clouds,
              potentially her S6, as well as her Outro skill's 38% Basic ATK DMG
              Amplify, do wonders for her as a character, rendering her able to
              make Encore's huge base damage even stronger.
            </li>
            <li>
              <WWCharacter mode="inline" slug="lingyang" enablePopover /> -
              Lingyang's best option is Zhezhi without a doubt, however Sanhua
              gets very close. Strongly amplifying his basic attack related
              damage by a whopping 38% via her Outro skill, not to mention her
              really short field time allowing Lingyang to take the spotlight
              that he needs, makes Sanhua a great partner for the lion dancer.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              Sanhua is surprisingly Havoc Rover's best hybrid option as well.
              As usual, her ability to amplify Basic ATK DMG by a whopping 38%
              via her Outro skill, as well as other buffs like Moonlit Clouds,
              and potentially her S6, all the while keeping very low field time
              - which allows your main DPS to take the spotlight - makes her,
              followed very closely by Danjin, the best choice for Havoc Rover.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'taoqi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - When
              it comes to Resonance Skill DMG nukes, no one does it better than
              Jinhsi. Taoqi's exact role in a team is to amplify Resonance Skill
              DMG, hence the Jinzhou Magistrate will capitalize on that ability
              the best of anyone in the cast. While the duo isn't top tier by
              any means, it sure does make for huge damage screenshots and a lot
              of oneshots on most enemies in the game.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover /> - In
              spite of Taoqi being a support herself, she will tend to run
              Moonlit Clouds in conjunction with a support to cause big
              Resonance Skill damage with a single DPS. Therefore, running
              Verina alongside Taoqi is by no means a bad option; Verina's
              tendency to be able to slot in any team while providing good ATK%
              and DMG Amplify buffs with one of the lowest field times in the
              game makes the duo a good pair.
            </li>
          </ul>
        </>
      )}
      {name === 'yangyang' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              While not being his best in slot duo, Xiangli Yao is a great Main
              DPS for Yangyang to partner up with, as he pairs up nicely with
              Yangyang's particular kit. Unique in her role of being the only
              hybrid buffer that is fully quickswap-friendly thanks to her Outro
              skill, these two are able to help each other use their swap cancel
              windows, on top of the Moonlit Clouds and potential S6 buffs
              provided by Yangyang, to dish out surprisingly good damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> -
              Similarly to Xiangli Yao, Changli is a great option to partner
              Yangyang with. When it comes to quickswaps, nobody does them more
              than Changli, and as a result the two end up forming a very
              competitive duo together. While it's by no means Changli's best in
              slot option, they dish out good damage as a pair, Yangyang's
              energy-generating Outro skill and the Moonlit Clouds and potential
              S6 buffs being welcome on Changli as well.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'yinlin' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> and
              Yinlin were spotted as one of the strongest pairs in the game
              since Jinhsi came out, and for good reason. Coordinated attacks to
              generate Jinhsi's forte stacks quickly, a Liberation DMG Amplify
              on Yinlin's Outro skill that Jinhsi uses somewhat, and the ability
              to use Jinhsi's swap cancel window on her second skill, make the
              two one of the strongest teams in the game. (Granted, Yinlin's
              slightly outclassed by Zhezhi in this team.)
            </li>
            <li>
              <WWCharacter mode="inline" slug="xiangli-yao" enablePopover /> -
              Yinlin is one of the most synergistic characters with Xiangli Yao
              at the time of his release, primarily thanks to her tremendously
              powerful Outro ability which grants both 20% Electro DMG
              amplification and 25% Liberation DMG amplification for 14 seconds.
              This buff is a tremendous boost to Xiangli Yao's ultimate burst
              window with all of his damage being Electro and over 50% of it
              being Liberation, the duration is also just right to fully buff
              the entirety of it assuming you don't make any mistakes. Yinlin
              also does respectable damage of her own and can run the Moonlit
              Clouds set if desired to further boost Xiangli Yao's burst damage
              potential.
            </li>
            <li>
              <WWCharacter mode="inline" slug="calcharo" enablePopover /> is
              Yinlin's best closed beta pairing that got unfortunately nerfed
              the most since the game's release. Nevertheless, the two still
              make a great pair that can clear the tower, despite having more
              trouble than they used to. Yinlin's Outro skill, buffing
              Liberation DMG by 25% and Electro DMG by 20%, makes her Calcharo's
              strongest partner. However, beware of the inconsistency issues
              Calcharo often presents.
            </li>
            <li>
              <WWCharacter mode="inline" slug="rover-havoc" enablePopover /> -
              Unless you play Calcharo in his suboptimal swap cancel style,
              Havoc Rover presents themselves as one of the best quickswap DPS
              pairings for Yinlin. Having very good burst damage together, and
              some great quickswap potential, the team ends up being stronger
              than you'd think, and is very much viable for clearing the tower,
              granted it should need high levels of investment.
            </li>
            <li>
              <WWCharacter mode="inline" slug="encore" enablePopover /> -
              Playing a very similar role to Havoc Rover, Encore presents
              herself as a very good quickswap DPS option for Yinlin. However,
              keep in mind Encore will much more enjoy having a Sanhua to pair
              herself with. That being said, if your Sanhua is taken by another
              one of your teams in the tower, consider pairing your Encore with
              Yinlin for some fun quickswap action that still dishes out very
              good damage thanks to Encore's big base damage.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'youhu' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="mortefi" enablePopover />,{' '}
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> &{' '}
              <WWCharacter mode="inline" slug="zhezhi" enablePopover /> - As a
              coordinated attack buffing specialist it should be no surprise
              that <strong className={`char-name ${element} `}>{name}</strong>{' '}
              synergizes very nicely with all of the top meta Coordinated Attack
              focused damage dealers like Mortefi, Yinlin and Zhezhi. All three
              of these characters aren't the main damage dealers of their
              respective top teams but thanks to Youhu's 100% damage
              amplification their damage contribution can be substantially
              boosted resulting in a nice overall team damage boost.
            </li>
          </ul>
        </>
      )}
      {name === 'yuanwu' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> - Yuanwu
              is an excellent Hybrid partner for Jinhsi who - out of all of her
              potential hybrid teammates - requires the least field time by far.
              Yuanwu doesn't offer to much in terms of total damage or buffs but
              he does offer a consistent permanently accessible Electro
              Coordinated Attack to assist with stacking up Jinhsi's Forte
              Gauge. Yuanwu is absolutely a top option for Jinhsi and should not
              be underestimated - he is easy to play, gives her what she needs
              and allows for you to focus on playing 2 characters for the most
              part instead of 3 due to his simplistic nature.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'xiangli-yao' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="yinlin" enablePopover /> is one
              of the most synergistic characters with Xiangli Yao at the time of
              his release, primarily thanks to her tremendously powerful Outro
              ability which grants both 20% Electro DMG amplification and 25%
              Liberation DMG amplification for 14 seconds. This buff is a
              tremendous boost to Xiangli Yao's ultimate burst window with all
              of his damage being Electro and over 50% of it being Liberation,
              the duration is also just right to fully buff the entirety of it
              assuming you don't make any mistakes. Yinlin also does respectable
              damage of her own and can run the Moonlit Clouds set if desired to
              further boost Xiangli Yao's burst damage potential.
            </li>
            <li>
              <WWCharacter mode="inline" slug="changli" enablePopover /> - As a
              quick swap specialist Changli very much prefers to be constantly
              switched in and out and can dish out some serious damage when
              played in this style correctly. Xiangli Yao has both the option to
              stay on field during his ultimate to take advantage of powerful
              amplification Outros but also to swap cancel any of his incredibly
              long attack animations for massive damage gains. Changli
              specifically excels at option 2 and is easily able to weave her
              attacks and combos throughout Xiangli Yao's ultimate burst window
              without you having to worry thanks to his tremendously long
              ultimate burst window. Changli also has a Liberation DMG% bonus on
              her Outro but it's generally far more productive to focus on her
              quick swap potential instead. Combining Changli and Xiangi Yao
              forms a fast paced double damage power house team which requires a
              bit of practice but is a satisfying duo.
            </li>
            <li>
              <WWCharacter mode="inline" slug="sanhua" enablePopover /> doesn't
              offer anything crazy in the personal damage department or any
              tremendously powerful Outro amplifications for Xiangli Yao but she
              is one of the best and most efficient carriers of the Moonlit
              Clouds set, something which absolutely will make a difference to
              your Xiangli Yao's damage. On top of the Moonlit set Sanhua also
              takes up very little field time and generates a substantial amount
              of resonance energy for the team, something which might make the
              difference between Xiangli Yao gaining his ultimate just a bit
              faster or saving you needing to chase down any energy rolls on
              gear. Finally of course Sanhua gains a considerable increase to
              her supportive capabilities with Sequences so if you have those
              her supportive capabilities only further increase (specifically
              her S6).
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      {name === 'zhezhi' && (
        <>
          <ul className="bigger-margin">
            <li>
              <WWCharacter mode="inline" slug="jinhsi" enablePopover /> thrives
              in teams centered around Coordinated Attacks - as they build her
              Forte stacks to deal huge Resonance Skill DMG nukes. In this
              sense, Zhezhi presents herself as the best partner for her by a
              mile; with an Outro skill that amplifies Resonance Skill DMG by
              25% - comprising about 75% of Jinhsi's damage spread - a kit with
              plenty of Coordinated Attacks, and even some slight energy
              regeneration via her Inherent Skill 2 that Jinhsi appreciates,
              they, as of patch 1.4, compose together the highest damaging duo
              in the entire game.
            </li>
            <li>
              <WWCharacter mode="inline" slug="lingyang" enablePopover /> is our
              only Glacio Main damage dealer currently, making him an obvious
              choice to take advantage of Zhezhi's outro Glacio DMG
              Amplification. Lingyang's longer rotations without quick swaps
              also have no issues working well with Zhezhi's infrequent but
              quick rotations she executes every 25 seconds. Both Zhezhi and
              Lingyang synergise decently but more importantly each allow
              eachother to take up exactly how much field time they want.
            </li>
            <li>
              <WWCharacter mode="inline" slug="verina" enablePopover />{' '}
              {verinaInfo}
            </li>
            <li>
              <WWCharacter mode="inline" slug="the-shorekeeper" enablePopover />{' '}
              {shoreInfo}
            </li>
          </ul>
        </>
      )}
      <div className={`content-header ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Example Teams
      </div>
      {(name === 'verina' ||
        name === 'the-shorekeeper' ||
        name === 'rover-spectro' ||
        name === 'baizhi') && (
        <>
          <div className="info-box">
            <p>
              <strong className={`char-name ${element}`}>{name}</strong>{' '}
              <strong>is very flexible</strong> and can be used in any team in
              the Support slot.{' '}
              {name === 'rover-spectro' &&
                'Keep in mind you will need S4 Rover Spectro to fulfil the role as that is when they obtain their heal.'}
            </p>
          </div>
        </>
      )}
      {name === 'yangyang' && (
        <>
          <div className="info-box">
            <p>
              <strong className={`char-name ${element}`}>{name}</strong>{' '}
              <strong>teams aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        </>
      )}
      {name === 'aalto' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1 - Hybrid</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="jiyan" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="aalto" enablePopover showLabel />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - Main DPS</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="aalto" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'calcharo' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1: Yinlin Hypercarry</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - Solo Carry</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="baizhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="baizhi"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Pick 2 out of the Support characters listed above, but one of
                the supports should wear the Moonlit Clouds set and the other
                the Rejuvenating Glow set.
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'camellya' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Balanced Team</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Double Support</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Low Spender</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="baizhi"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'changli' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="xiangli-yao"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #4 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'chixia' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Double DPS</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="chixia"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'danjin' && (
        <>
          <p>
            Danjin's kit makes her playable in many different ways. Thanks to
            her access to shorter lower damage rotations and sustained damage
            over extended periods of time, she can be played as a standard
            hybrid, a standard main DPS, but also a very potent solo carry. Pick
            whatever team suits you best, as they're all viable and competitive
            (with the fastest teams being shown first).
          </p>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Camellya Hybrid</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="camellya"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Havoc Rover Hybrid</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3 - Main DPS</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #4 - Solo Carry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="baizhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="baizhi"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-notes">
              <ul>
                <li>
                  Pick 2 out of the Support characters listed above, but one of
                  the supports should wear the Moonlit Clouds set and the other
                  the Rejuvenating Glow set.
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {name === 'encore' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Hypercarry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Quickswap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="changli"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'jianxin' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Hyper Carry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="zhezhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yuanwu"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <p>Here are the teams in action:</p>
          <Row className="video">
            <Col xs={12} lg={6}>
              <YoutubeEmbed embedId="R39BYwXU5Uc" />
            </Col>
          </Row>
        </>
      )}
      {name === 'jiyan' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Hypercarry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="aalto"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'lingyang' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Hypercarry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lingyang"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="zhezhi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Hypercarry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lingyang"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'lumi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - With Jinhsi</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="lumi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="baizhi"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'mortefi' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="mortefi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jiyan"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jianxin"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - Quick Swap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="danjin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3 - Hyper Carry</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="sanhua"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'sanhua' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="camellya"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="encore"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #3</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="rover-havoc"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #4</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="lingyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'taoqi' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1: Nuke Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="taoqi" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Make sure Taoqi is running Moonlit Clouds and is the one
                outroing into Jinhsi!
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'yinlin' && (
        <>
          <div className={`character-teams box ${element}`}>
            <div className="team-header">
              <p>Team #1 - With Jinhsi</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="jinhsi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #2 - With Xiangli Yao</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="xiangli-yao"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #3 - With Calcharo</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="calcharo"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #4 - Quickswap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="rover-havoc"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
            <div className="team-header">
              <p>Team #5 - Quickswap</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="encore"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main">
                  <WWCharacter
                    mode="icon"
                    slug="yinlin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character main multi">
                  <WWCharacter
                    mode="icon"
                    slug="verina"
                    enablePopover
                    showLabel
                  />
                  <WWCharacter
                    mode="icon"
                    slug="the-shorekeeper"
                    enablePopover
                    showLabel
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'youhu' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1 - Jiyan Carry</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter mode="icon" slug="jiyan" enablePopover showLabel />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="mortefi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - Double Electro</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #3 - Jinhsi</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
              </div>
            </div>
          </div>
          <div className="team-notes">
            <ul>
              <li>
                Verina is better than Youhu in this team, but if you're using
                her in your second team, Youhu can work here - it's a DPS loss
                for the team though.
              </li>
            </ul>
          </div>
        </div>
      )}
      {name === 'yuanwu' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yuanwu"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'xiangli-yao' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1 - Double Electro</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="yinlin"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter mode="icon" slug="youhu" enablePopover showLabel />
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - Quick Swap Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="changli"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #3 - Low Spender Team</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="xiangli-yao"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="baizhi"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'zhezhi' && (
        <div className={`character-teams box ${element}`}>
          <div className="team-header">
            <p>Team #1 - With Jinhsi</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="jinhsi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="team-header">
            <p>Team #2 - With Lingyang</p>
          </div>
          <div className="single-team">
            <div className="team-row">
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="lingyang"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main">
                <WWCharacter
                  mode="icon"
                  slug="zhezhi"
                  enablePopover
                  showLabel
                />
              </div>
              <div className="character main multi">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
                <WWCharacter
                  mode="icon"
                  slug="the-shorekeeper"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
