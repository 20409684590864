export const WWStatsJSON = [
  {
    slug: 'lingyang',
    name: 'Lingyang',
    unit_id: 1104,
    base_hp: 831,
    base_atk: 35,
    base_def: 99,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'calcharo',
    name: 'Calcharo',
    unit_id: 1301,
    base_hp: 840,
    base_atk: 35,
    base_def: 97,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 16,
    bonus_hp: 0
  },
  {
    slug: 'jiyan',
    name: 'Jiyan',
    unit_id: 1404,
    base_hp: 839,
    base_atk: 35,
    base_def: 97,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'encore',
    name: 'Encore',
    unit_id: 1203,
    base_hp: 841,
    base_atk: 34,
    base_def: 102,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'yinlin',
    name: 'Yinlin',
    unit_id: 1302,
    base_hp: 880,
    base_atk: 32,
    base_def: 105,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'verina',
    name: 'Verina',
    unit_id: 1503,
    base_hp: 1139,
    base_atk: 27,
    base_def: 90,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 175,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 12,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'jianxin',
    name: 'Jianxin',
    unit_id: 1405,
    base_hp: 1129,
    base_atk: 27,
    base_def: 92,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 150,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'chixia',
    name: 'Chixia',
    unit_id: 1202,
    base_hp: 727,
    base_atk: 24,
    base_def: 78,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 150,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'sanhua',
    name: 'Sanhua',
    unit_id: 1102,
    base_hp: 805,
    base_atk: 22,
    base_def: 77,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 100,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'danjin',
    name: 'Danjin',
    unit_id: 1602,
    base_hp: 755,
    base_atk: 21,
    base_def: 94,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 100,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'aalto',
    name: 'Aalto',
    unit_id: 1403,
    base_hp: 788,
    base_atk: 21,
    base_def: 88,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 150,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'mortefi',
    name: 'Mortefi',
    unit_id: 1204,
    base_hp: 802,
    base_atk: 20,
    base_def: 93,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'yangyang',
    name: 'Yangyang',
    unit_id: 1402,
    base_hp: 816,
    base_atk: 20,
    base_def: 90,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 100,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'taoqi',
    name: 'Taoqi',
    unit_id: 1601,
    base_hp: 716,
    base_atk: 18,
    base_def: 128,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 0,
    bonus_def: 1.152,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'yuanwu',
    name: 'Yuanwu',
    unit_id: 1303,
    base_hp: 682,
    base_atk: 18,
    base_def: 134,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 0,
    bonus_def: 1.152,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'baizhi',
    name: 'Baizhi',
    unit_id: 1103,
    base_hp: 1025,
    base_atk: 17,
    base_def: 82,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 175,
    bonus_ele: 0,
    bonus_atk: 0,
    bonus_def: 0,
    bonus_heal: 12,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 1.12
  },
  {
    slug: 'rover-spectro',
    name: 'Rover (Spectro)',
    unit_id: 1501,
    base_hp: 912,
    base_atk: 30,
    base_def: 112,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'rover-havoc',
    name: 'Rover (Havoc)',
    unit_id: 1502,
    base_hp: 866,
    base_atk: 33,
    base_def: 103,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 1.12,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'jinhsi',
    name: 'Jinhsi',
    unit_id: 1304,
    base_hp: 866,
    base_atk: 33,
    base_def: 103,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'changli',
    name: 'Changli',
    unit_id: 1205,
    base_hp: 831,
    base_atk: 37,
    base_def: 90,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'zhezhi',
    name: 'Zhezhi',
    unit_id: 1105,
    base_hp: 980,
    base_atk: 30,
    base_def: 98,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'xiangli-yao',
    name: 'Xiangli Yao',
    unit_id: 1305,
    base_hp: 850,
    base_atk: 34,
    base_def: 100,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 16,
    bonus_hp: 0
  },
  {
    slug: 'the-shorekeeper',
    name: 'The Shorekeeper',
    unit_id: 1505,
    base_hp: 1337,
    base_atk: 23,
    base_def: 90,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 1.12,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 0,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 0,
    bonus_hp: 1.12
  },
  {
    slug: 'youhu',
    name: 'Youhu',
    unit_id: 1106,
    base_hp: 798,
    base_atk: 21,
    base_def: 86,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  },
  {
    slug: 'camellya',
    name: 'Camellya',
    unit_id: 1603,
    base_hp: 826,
    base_atk: 36,
    base_def: 95,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 0,
    bonus_critdmg: 16,
    bonus_hp: 0
  },
  {
    slug: 'lumi',
    name: 'Lumi',
    unit_id: 1504,
    base_hp: 680,
    base_atk: 27,
    base_def: 72,
    base_crit: 5,
    base_critdmg: 150,
    base_healing: 0,
    max_energy: 125,
    bonus_ele: 0,
    bonus_atk: 1.12,
    bonus_def: 0,
    bonus_heal: 0,
    bonus_crit: 8,
    bonus_critdmg: 0,
    bonus_hp: 0
  }
];
